<template>
  <div class="Home">
    <div class="header">
      <div class="content">
        <div class="title">廉政宣传</div>
        <div class="searchBox">
          <el-autocomplete class="input" v-model="searchKey" :fetch-suggestions="querySuggestions" placeholder="请输入"
            :trigger-on-focus="false" @select="goDetail" @keyup.enter.native="searchResult">
            <template slot-scope="{ item }">
              <div class="suggestion">
                {{ item.title }}
              </div>
            </template>
            <el-button slot="append" class="btn"  @click="searchResult">搜索</el-button>
          </el-autocomplete>

        </div>
      </div>
    </div>
    <div class="body">
      <div class="sider">
        <h3>条件筛选</h3>
        <el-collapse v-model="siderCollapse">
          <el-collapse-item title="年度" name="year">
            <el-radio class="siderRadio" v-model="filter.year" label="">全部</el-radio>
            <el-radio class="siderRadio" v-model="filter.year" label="2024">2024</el-radio>
            <el-radio class="siderRadio" v-model="filter.year" label="2023">2023</el-radio>
            <el-radio class="siderRadio" v-model="filter.year" label="2022">2022</el-radio>
          </el-collapse-item>
          <el-collapse-item title="类型" name="type">
            <el-radio class="siderRadio" v-model="filter.type" label="">全部</el-radio>
            <el-radio class="siderRadio" v-model="filter.type" label="0">文章</el-radio>
            <el-radio class="siderRadio" v-model="filter.type" label="1">绘画</el-radio>
            <el-radio class="siderRadio" v-model="filter.type" label="2">书法</el-radio>
            <el-radio class="siderRadio" v-model="filter.type" label="3">摄影</el-radio>
            <el-radio class="siderRadio" v-model="filter.type" label="4">视频</el-radio>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="main">
        <div class="list">
          <div class="listItem" v-for="item in this.list" :key="item.id" @click="goDetail(item)">
            <div class="coverWraper">
              <img class="coverImage" v-if="item.cover.type === 'IMAGE'" :src="item.cover.url"
                :style="{ width: item.cover.width + 'px', height: item.cover.height + 'px' }" />
              <div class="coverVideo" v-if="item.cover.type === 'VIDEO'">
                <img :src="item.cover.url"
                  :style="{ width: item.cover.width + 'px', height: item.cover.height + 'px' }" />
                <img class="player" src="../../assets/play.png" alt="">
              </div>
              <div class="coverText" v-if="item.cover.type === 'TEXT'">
                {{ item.cover.url }}
              </div>
            </div>
            <div class="title">{{ item.title }}</div>
          </div>
        </div>
        <div class="pagination">
          <el-pagination layout="prev, pager, next" :total="total" :page-size="20" @current-change="loadList"
            :current-page.sync="currentPage">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from '../utils/debounce';
import { apiSearch, apiLoadList } from '../services/api';
export default {
  name: 'HomePage',
  data() {
    return {
      searchKey: '',
      siderCollapse: [
        'year',
        'type',
      ],
      filter: {
        year: '',
        type: '',
      },
      currentPage: 1,
      list: [],
      total: 0,
    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.searchResult()
      }
    }
  },
  methods: {
    querySuggestions: debounce(async (word, cb) => {
      try {
        const res = await apiSearch({ word });
        cb(res);
      } catch (e) {
        cb([]);
      }
    }),
    goDetail(item) {
      console.log('打开详情', item)
    },
    async searchResult() {
      this.currentPage = 1;
      this.loadList()
    },
    async loadList() {
      const res = await apiLoadList({
        currentPage: this.currentPage,
        year: this.filter.year,
        type: this.filter.type,
        keyword: this.searchKey,
      })
      this.list = res.list;
      this.total = res.total;
    },
  },
  mounted() {
    this.searchResult()
  }
}
</script>

<style lang="less" scoped>
@contentWidth: 1200px;

.header {
  // padding: 60px 0 80px;
  background-color: #0073FF;
  // // background-image: url('/banner.png');
  // background-size: cover;
  height: 60px;
  color: #fff;
  // border-bottom: 1px solid #f4f4f4;

  .content {
    width: @contentWidth;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  // color: #fff;



  .title {
    font-size: 24px;
    font-weight: bold;
    // margin-bottom: 40px;
  }

  .searchBox {
    display: flex;
    justify-content: center;

    .input {
      width: 400px;
    }

    // .btn {
    //   margin-left: 10px;
    // }
  }

}

.body {
  width: @contentWidth;
  margin: 0 auto;
  display: flex;
  min-height: 600px;

  .sider {
    width: 200px;
    padding-right: 10px;
    border-right: 1px solid #f4f4f4;
    flex-shrink: 0;

    .siderRadio {
      display: block;
      height: 32px;
      line-height: 32px;
    }

  }

  .main {
    .list {
      margin: 24px 0 0 20px;
      display: flex;
      flex-wrap: wrap;
      gap: 24px 20px;

      .listItem {
        width: 230px;
        height: 320px;
        border-radius: 12px;
        background-color: #edf2f5;
        cursor: pointer;

        .coverWraper {
          width: 230px;
          height: 280px;
          background: linear-gradient(173deg, #edf2f5, #f7f9fa), #fff;
          border-radius: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          &:hover {
            background: linear-gradient(173deg, #e0e8ec, #e4eaee), #fff;
          }

          .coverImage {
            border-radius: 6px;
            display: block;
          }

          .coverVideo {
            border-radius: 6px;
            overflow: hidden;
            position: relative;

            img {
              display: block
            }

            .player {
              width: 40px;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);

            }
          }

          .coverText {
            border-radius: 6px;
            // background: linear-gradient(180deg, #f7fdff, #e6edf1);
            // color: #8f979f;
            // background: #444;
            // color: #fff;
            color: #8f979f;
            padding: 12px;
            width: 190px;
            max-height: 220px;
            overflow: hidden;
            font-size: 12px;

            text-indent: 2em;
            line-height: 1.7em;

            text-overflow: ellipsis;
            -webkit-line-clamp: 10;
            display: -webkit-box;
            -webkit-box-orient: vertical;

          }
        }

        .title {
          height: 40px;
          line-height: 40px;
          padding: 0 16px;
          color: #161618;
          font-weight: 600;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 14px;
          user-select: none;
        }
      }
    }

    .pagination {
      padding: 32px;
      display: flex;
      justify-content: center;
    }

  }
}
</style>