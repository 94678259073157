import { waitTime } from "../utils/time";
import { Random } from "mockjs";

export const apiSearch = async () => {
  await waitTime(500);
  return new Array(10).fill(0).map(() => {
    return {
      id: Random.id(),
      title: Random.ctitle(),
    };
  });
};

export const apiLoadList = async (params) => {
  console.log("apiLoadList", params);
  const fakeThumbInfoRes = await fetch("./faker-thumb/info.json");
  const fakeThumbInfo = await fakeThumbInfoRes.json();
  console.log("fakeThumbInfo", fakeThumbInfo)

  const list = new Array(20).fill(0).map(() => {
    const type = params.type === '' ?  Random.integer(0, 4).toString() : params.type;
    let cover;
    if (type === '0') {
      cover = {
        type: "TEXT",
        url: Random.cparagraph() + Random.cparagraph(),
        width: 0,
        height: 0,
      };
    } else {
      const name = `${type}-${Random.integer(1, 9)}.jpg`
      const url = `./faker-thumb/${name}`;
      cover = {
        type: type === '4' ? "VIDEO" : "IMAGE",
        width: fakeThumbInfo[name].width,
        height: fakeThumbInfo[name].height,
        url,
      };
    }

    if(cover.width) {
      const scale = Math.min(190 / cover.width, 240 / cover.height);
      cover.width = Math.floor(cover.width * scale);
      cover.height = Math.floor(cover.height * scale);
    }

    // if (cover.type === "IMAGE") {
    //   cover.url = faker.image.url({ width: cover.width, height: cover.height });
     
    // } else if (cover.type === "VIDEO") {
    //   cover.url = faker.image.url({ width: 384, height: 216 });
    //   cover.width = 190;
    //   cover.height = Math.floor((190 * 9) / 16);
    // } else if (cover.type === "TEXT") {
    //   cover.url = Random.cparagraph() + Random.cparagraph();
    // }
    return {
      id: Random.id(),
      title: Random.ctitle(),
      cover,
      type,
    };
  });
  const total = Random.integer(100, 200);
  return {
    list,
    total,
  };
};
