<template>
  <div id="ossview">
    <router-view ></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {

  },
  mounted() {
    this.$router.push('/')
  }
}
</script>

<style lang="less">
body {
  margin: 0;
}
#ossview {
  width: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
  min-height: 100vh;

  * {
    box-sizing: border-box;
  }

  a {
    color: #52aeff;
    text-decoration: none;
  }
}
</style>
