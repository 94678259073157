export function debounce(fun, wait = 1000) {
  let timeout = null;
  return function () {
    const _this = this;
    const arg = arguments;
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(function () {
      fun.apply(_this, arg);
    }, wait);
  };
}
