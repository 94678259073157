import VueRouter from 'vue-router'
import Home from './pages/Home.vue'

const routes = [
  { path: '/', name: 'home', component: Home },
]

const router = new VueRouter({
  mode: 'abstract',
  base: '/',
  routes
})

export default router